import React from 'react'

import styles from '../assets/scss/templates/Banner.module.scss'

const Banner = props => {
    return (
        <div
            id="Banner"
            className={styles.Banner}
            style={{
                background: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${
                    props.image
                }') no-repeat center center`,
                backgroundSize: 'cover',
            }}
        >
            <div>
                <h1 title={props.title}>{props.title}</h1>
                <span>{props.subtitle}</span>
                {props.date === null ? '' : <span> - {props.date}</span>}
            </div>
        </div>
    )
}

export default Banner

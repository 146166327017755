import React from 'react'

import Social from './social'
import Listing from './listing'
import LatestPosts from './latest-posts'

import styles from '../assets/scss/templates/Sidenav.module.scss'

const Sidenav = props => (
    <div className={styles.Sidenav}>
        <div>
            {/* <Author author={props.author}/> */}
            <h4>Follow</h4>
            <Social />
            <LatestPosts items={props.posts} />
            <Listing
                to="category"
                items={props.categories}
                title={'Categories'}
            />
            <Listing to="tag" items={props.tags} title={'Tags'} />
        </div>
    </div>
)

export default Sidenav

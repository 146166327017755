import React from 'react'
import Link from 'gatsby-link'

import { kebabCase } from 'lodash'

const CategoriesListing = props => (
    <div>
        <h4>{props.title}</h4>
        <ul>
            {props.items.map((item, index) => (
                <li key={index}>
                    <Link to={`/${props.to}/${kebabCase(item.fieldValue)}/`}>
                        {item.fieldValue} ({item.totalCount})
                    </Link>
                </li>
            ))}
        </ul>
    </div>
)

export default CategoriesListing

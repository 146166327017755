export default {
    category_news: {
        title: 'Category: News',
        subtitle: 'Our latest news',
    },
    category_usecase: {
        title: 'Category: Use-case',
        subtitle: 'Article for DLT use-cases',
    },
    category_interview: {
        title: 'Category: Interview',
        subtitle: 'Interviews of our members',
    },
    category_opinion: {
        title: 'Category: Opinion',
        subtitle: 'Our opinion on Blockchain revolution',
    },
    tag_interview: {
        title: 'Tag: Interview',
        subtitle: 'Interviews of our members',
    },
    tag_news: {
        title: 'Tag: News',
        subtitle: 'Our latest news',
    },
    tag_ico: {
        title: 'Tag: ICO',
        subtitle: 'Initial coin offer',
    },
}

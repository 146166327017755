import React from 'react'
import Link from 'gatsby-link'

const LatestPosts = props => (
    <div>
        <h4>Recent Posts</h4>
        <ul>
            {props.items.map((item, index) => (
                <li key={index}>
                    <Link to={item.node.frontmatter.path}>
                        {item.node.frontmatter.title}
                    </Link>
                </li>
            ))}
        </ul>
    </div>
)

export default LatestPosts

import React from 'react'
import { graphql } from 'gatsby'
import Link from 'gatsby-link'

import styles from '../assets/scss/templates/PostListTemplate.module.scss'

import Layout from '../components/layout'
import Banner from './banner'
import Sidenav from './sidenav'
import BannerInfo from '../utils/banner-info'
// import SEO from "../utils/SEO";

export default function Category(props) {
    var bannerInfo = BannerInfo[props.pageContext.slug]

    return (
        <Layout>
            <div>
                {/* <SEO title={bannerInfo.title} isPost={false}/> */}
                <Banner
                    title={`${bannerInfo.title}`}
                    subtitle={bannerInfo.subtitle}
                    date={null}
                />
                <div className={styles.PostTemplate}>
                    <div>
                        <div className={styles.Content}>
                            {props.data[props.pageContext.type].edges.map(
                                (item, index) => (
                                    <div key={index}>
                                        <h4>
                                            <Link
                                                to={item.node.frontmatter.path}
                                            >
                                                {item.node.frontmatter.title}
                                            </Link>
                                        </h4>
                                        <p>{item.node.excerpt}</p>
                                    </div>
                                )
                            )}
                        </div>
                        <Sidenav
                            categories={props.data.allMarkdownRemark.categories}
                            posts={props.data.recentPosts.edges}
                            tags={props.data.allMarkdownRemark.tags}
                        />
                    </div>
                </div>
            </div>
        </Layout>
    )
}

export const pageQuery = graphql`
    query BlogPostByCategory($q: String!) {
        category: allMarkdownRemark(
            filter: { frontmatter: { category: { eq: $q } } }
            sort: { order: DESC, fields: [frontmatter___date] }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 250)
                    frontmatter {
                        path
                        title
                    }
                }
            }
        }
        tag: allMarkdownRemark(
            filter: { frontmatter: { tags: { eq: $q } } }
            sort: { order: DESC, fields: [frontmatter___date] }
        ) {
            edges {
                node {
                    excerpt(pruneLength: 250)
                    frontmatter {
                        path
                        title
                    }
                }
            }
        }
        recentPosts: allMarkdownRemark(
            sort: { order: DESC, fields: [frontmatter___date] }
        ) {
            edges {
                node {
                    frontmatter {
                        path
                        title
                        author
                        date(formatString: "MMMM DD, YYYY")
                    }
                }
            }
        }
        allMarkdownRemark(limit: 2000) {
            categories: group(field: frontmatter___category) {
                fieldValue
                totalCount
            }
            tags: group(field: frontmatter___tags) {
                fieldValue
                totalCount
            }
        }
    }
`

import React from 'react'

const Social = props => (
    <ul className="icons">
        <li>
            <a
                name="twitter"
                target="_blank"
                rel="noopener noreferrer"
                href="https://twitter.com/synapheachain/"
                className="icon alt fa-twitter"
            >
                <span className="label">Twitter</span>
            </a>
        </li>
        <li>
            <a
                name="facebook"
                target="_blank"
                rel="noopener noreferrer"
                href="https://facebook.com/synaphea/"
                className="icon alt fa-facebook"
            >
                <span className="label">Facebook</span>
            </a>
        </li>
        <li>
            <a
                name="instagram"
                target="_blank"
                rel="noopener noreferrer"
                href="https://www.instagram.com/synaphea/"
                className="icon alt fa-instagram"
            >
                <span className="label">Instagram</span>
            </a>
        </li>
        <li>
            <a
                name="github"
                target="_blank"
                rel="noopener noreferrer"
                href="https://github.com/synaphea/"
                className="icon alt fa-github"
            >
                <span className="label">GitHub</span>
            </a>
        </li>
        <li>
            <a
                name="linkedin"
                target="_blank"
                rel="noopener noreferrer"
                href="https://linkedin.com/company/synaphea/"
                className="icon alt fa-linkedin"
            >
                <span className="label">LinkedIn</span>
            </a>
        </li>
    </ul>
)

export default Social
